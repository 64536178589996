body {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  header {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem;
  }

  .sidebar {
    width: 200px;
    background-color: #333;
    color: white;
    padding: 1rem;
    transition: transform 0.3s; 
    transform: translateX(0); 
  }

  .sidebar.collapsed {
    transform: translateX(-200px); 
  }

  .sidebar-toggle {
    display: block; 
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .sidebar.collapsed .sidebar-toggle {
    display: block; 
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    margin-bottom: 1rem;
  }

  .sidebar li a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s, opacity 0.3s, color 0.3s; /* Add color transition */
  }

  .sidebar li a:hover {
    background-color: #555;
    color: #ff9800; /* Change text color on hover */
  }

  .sidebar li a:focus {
    background-color: #555;
    outline: none;
  }

  .sidebar li {
    margin-bottom: 1rem;
  }

  .sidebar li:focus {
    outline: 2px solid blue; 
  }

  .sidebar li a.active {
    background-color: #666;
  }

  .sidebar-toggle span {
    font-size: 1.5rem; 
    font-weight: bold;
    color: #fff; 
    transition: color 0.3s;
  }
  
  .sidebar-toggle:hover span {
    color: #ff9800; 
  }

  .sidebar-toggle:focus {
    outline: none;
    /* Add a visual indicator to show focus */
    box-shadow: 0 0 3px 2px rgba(150, 116, 5, 0.5);
    color: #ff9800; /* Change color on focus */
  }

  .sidebar-toggle:focus span {
    /* Add a visual indicator when the button is focused */
    outline: none;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.5);
  }
  
  .content {
    flex: 1;
    padding: 1rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  p {
    font-style: italic;
    font-weight: bold;
    color: #333;
    background-color: yellow;
    padding: 12px;
    border-radius: 5px;
  }
  
    a:focus,
    button:focus,
    input:focus,
    textarea:focus,
    select:focus {
        outline: 2px solid #007bff;
        outline-offset: 2px;
  }


